import { useEffect, useState } from "react"

import { useOpenBarrierMutation } from "../api/appApi"

import Title from "./common/Title"

const ParkingControl = () => {
    const [opened, setOpened] = useState<boolean>(false)

    const [openBarrier] = useOpenBarrierMutation()

    const handleOpenGates = () => {
        openBarrier().then((res) => {
            if ("error" in res) {
                return
            } else {
                setOpened(true)
            }
        })
    }

    useEffect(() => {
        if (opened) {
            setTimeout(() => {
                setOpened(false)
            }, 15000)
        }
    }, [opened])

    return (
        <div className="flex flex-col p-6 py-8 gap-4">
            <Title title="Керування" />
            <div className="flex flex-col gap-4">
                <h5>
                    В цій панелі можна керувати парковкою, відчиняти шлагбаум.
                </h5>
                <button disabled={opened} onClick={handleOpenGates} className="px-4 h-[44px] flex items-center justify-center rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 cursor-pointer gap-2 text-base font-semibold select-none w-fit min-w-[200px] disabled:opacity-50 disabled:hover:bg-buttonColor">
                    {opened ? 'Відчинено' : 'Відчинити шлагбаум'}
                </button>
            </div>
        </div>
    )
}

export default ParkingControl