import { Navigate, Route, Routes } from "react-router-dom"

import { useAppSelector } from "../redux/hooks"

import ParkingControl from "../components/ParkingControl"
import ParkingEvents from "../components/ParkingEvents"
import SystemEvents from "../components/SystemEvents"
import Automobiles from "../components/Automobiles"
import Navigation from "../components/Navigation"
import Overview from "../components/Overview"
import Parkings from "../components/Parkings"
import Payments from "../components/Payments"
import Users from "../components/Users"

const Dashboard = () => {
    const userInfo = useAppSelector((state) => state.app.userInfo)

    return (
        <div className="grid grid-cols-[260px,1fr] w-full h-screen">
            <Navigation />
            <div className="w-full h-screen overflow-y-auto">
                <Routes>
                    {userInfo?.role === 'ADMIN' && (
                        <>
                            <Route path='/' element={<Overview />} />
                            <Route path='/users' element={<Users />} />
                            <Route path='/cars' element={<Automobiles />} />
                            <Route path='/system-events' element={<SystemEvents />} />
                            <Route path='/control' element={<ParkingControl />} />
                        </>
                    )}
                    <Route path='/parkings' element={<Parkings />} />
                    <Route path='/payments' element={<Payments />} />
                    <Route path='/parking-events' element={<ParkingEvents />} />
                    <Route path='*' element={<Navigate to={userInfo?.role === 'ADMIN' ? '/' : '/parkings'} />} />
                </Routes>
            </div>
        </div>
    )
}

export default Dashboard